import React from 'react';
import { CloudUpload } from 'components/images/CloudUploadImg';
import { CustomButton } from './CustomButton';


interface UploadExternalProps {
  onClick: () => void;
}

export const UploadExternalButton: React.FC<UploadExternalProps> = ({ onClick }) => {

  return (
    <CustomButton onClick={onClick} 
                  customSize="l"  
                  type="submit"
                  customColor="#1C3C6C" 
                  >
      <CloudUpload fontSize='small'/> 
      Upload external data
    </CustomButton>
  );
};
